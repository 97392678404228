<template>
    <div>
        <el-tooltip v-if="tools.includes('update') && permissionUpdate" effect="dark" content="编辑" placement="top">
            <i class="el-icon-edit edit-icon"  @click="openUpdate"></i>
        </el-tooltip>
        <el-tooltip v-if="tools.includes('auth')" effect="dark" content="查看认证信息" placement="top">
            <i class="el-icon-document-checked edit-icon"  @click="openAuth"></i>
        </el-tooltip>
        <el-tooltip v-if="tools.includes('detail') && permissionDetail" effect="dark" :content="detailText" placement="top">
            <i class="el-icon-document edit-icon" @click="onDetail"></i>
        </el-tooltip>
        <el-tooltip v-if="tools.includes('mobile') && permissionMobile" effect="dark" content="短信记录" placement="top">
            <i class="el-icon-mobile-phone edit-icon" @click="onMobile"></i>
        </el-tooltip>
        <el-tooltip v-if="tools.includes('del') && permissionDel" effect="dark" content="删除" placement="top">
            <i class="el-icon-delete del-icon"  @click="delRecord"></i>
        </el-tooltip>
        <el-tooltip v-if="tools.includes('revoke') && permissionRevoke" effect="dark" content="撤销" placement="top">
            <i class="el-icon-top-left del-icon"  @click="revoke"></i>
        </el-tooltip>
        <el-tooltip v-if="tools.includes('power') && permissionPower" effect="dark" content="设置权限" placement="top">
            <i v-if="powerLoading" class="el-icon-loading del-icon"></i>
            <i v-else class="el-icon-sunset del-icon" @click="onPower"></i>
        </el-tooltip>
        <el-tooltip v-if="tools.includes('copy') && permissionCopy" effect="dark" content="复制权限" placement="top">
            <i class="el-icon-copy-document del-icon ml20" @click="onCopy"></i>
        </el-tooltip>
        <el-tooltip v-if="tools.includes('passWord') && permissionPassWord" effect="dark" content="重置密码" placement="top">
            <i class="el-icon-lock del-icon" @click="onPass"></i>
        </el-tooltip>
        <el-tooltip v-if="tools.includes('place') && permissionPlace" effect="dark" content="投放社区" placement="top">
            <i class="el-icon-place del-icon" @click="onPlace"></i>
        </el-tooltip>
        <el-tooltip v-if="tools.includes('copycontent') && permissionCopy" effect="dark" content="复制" placement="top">
            <i class="el-icon-copy-document del-icon ml20" @click="onCopycontent"></i>
        </el-tooltip>
        <el-tooltip v-if="tools.includes('check') && permissionCheck" effect="dark" content="审核" placement="top">
            <i class="el-icon-document-checked del-icon ml20" @click="onCheck"></i>
        </el-tooltip>
        <el-tooltip v-if="tools.includes('view') && permissionView" effect="dark" content="详情" placement="top">
            <i class="el-icon-view del-icon ml20" @click="onView"></i>
        </el-tooltip>
        <slot></slot>
    </div>
</template>
<script>
    export default {
        props: {
            tools: {
                type: Array,
                default() {
                    return []
                }
            },
            powerLoading: {
                type: Boolean,
                default: true
            },
            permissionUpdate: {
                type: Boolean,
                default: true
            },
            permissionDel: {
                type: Boolean,
                default: true
            },
            permissionPower: {
                type: Boolean,
                default: true
            },
            permissionCopy: {
                type: Boolean,
                default: true
            },
            permissionPassWord: {
                type: Boolean,
                default: true
            },
            permissionPlace: {
                type: Boolean,
                default: true
            },
            permissionRevoke: {
                type: Boolean,
                default: true
            },
            permissionDetail: {
                type: Boolean,
                default: true
            },
            permissionMobile: {
                type: Boolean,
                default: true
            },
            detailText: {
                type: String,
                default: '查看详情'
            },
            permissionCheck: {
                type: Boolean,
                default: true
            },
            permissionView: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {

            }
        },
        methods: {
            openUpdate() {
                this.$emit('update')
            },
            openAuth() {
                this.$emit('auth')
            },
            delRecord() {
                this.$emit('del')
            },
            onPower() {
                this.$emit('power')
            },
            onCopy() {
                this.$emit('copy')
            },
            onPass() {
                this.$emit('passWord')
            },
            onPlace() {
                this.$emit('place')
            },
            onDetail() {
                this.$emit('detail')
            },
            revoke() {
                this.$emit('revoke')
            },
            onMobile() {
                this.$emit('mobile')
            },
            onCopycontent() {
                this.$emit('copycontent')
            },
            onCheck() {
                this.$emit('check')
            },
            onView() {
                this.$emit('view')
            }
            
        }
    }
</script>
<style lang="less" scoped>
    @import "@/assets/css/base.less";
    .edit-icon {
        font-size: 18px;
        color: #999999;
        margin-right: 20px;
        cursor: pointer;
        &:hover {
            color: @mainColor;
        }
    }
    .del-icon {
        font-size: 18px;
        color: #999999;
        cursor: pointer;
        &:hover {
            color: @mainColor;
        }
    }
</style>