import { instance, instanceNoToken, instanceDownload } from './ajax.js'

export const CommonApi = {
    upload(obj) {
        return instance.post(`/api/minio/base/upload?bucketName=${obj.params.bucketName}&folderName=${obj.params.folderName}`, obj.formData)
    },
    getPermission(params) {
        return instance.get('/api/auth/v1/menu/getPermission', { params })
    }
}

//登陆
export const LoginApi = {
    login(params) {
        return instanceNoToken.post('/api/auth/v1/login/password', params)
    },
    refreshToken(params) {
        return instanceNoToken.post('/api/auth/v1/login/refreshToken', params)
    },
    checkCode(params) {
        return instanceNoToken.get('/api/auth/v1/login/check', { params })
    },
    logout(params) {
        return instance.post('/api/auth/v1/logout', params)
    },
    //
    personalInfo(params) {
        return instance.get(`/api/auth/v1/user/personalInfo/${params.id}`)
    },
    updatePwd(params) {
        return instance.put('/api/auth/v1/user/updatePwd', params)
    },
}

/* 菜单api  */
export const MenuApi = {
    userRootMenu(params) {
        return instance.get('/api/auth/v1/menu/userRootMenu', { params })
    },
    userMenu(params) {
        return instance.get('/api/auth/v1/menu/userMenu/' + params.pid)
    },
    tree(params) {
        return instance.get('/api/auth/v1/menu/tree', { params })
    },
    allTree(params) {
        return instance.get('/api/auth/v1/menu/allTree', { params })
    },
    roleMenuTree(params) {
        return instance.get('/api/auth/v1/role/roleMenuTree', { params })
    },
    list(obj) {
        return instance.get(`/api/auth/v1/menu/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    menuDetail(params) {
        return instance.get(`/api/auth/v1/menu/${params.id}`)
    },
    menu(params) {
        return instance.post(`/api/auth/v1/menu`, params)
    },
    updateMenu(params) {
        return instance.put(`/api/auth/v1/menu`, params)
    },
    status(params) {
        return instance.put(`/api/auth/v1/menu/status`, params)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/menu/${params.id}`)
    }
}

/* 行政区域api */
export const BasicRegionApi = {
    regionTree(params) {
        return instance.get(`/api/auth/v1/basicRegion/regionTree`, { params })
    },
    buildingsRegionTree(params) {
        return instance.get(`/api/auth/v1/basicRegion/buildingsRegion/tree/${params.id}`)
    },
    region(params) {
        return instance.get(`/api/auth/v1/basicRegion/under/region`, { params })
    },
    fiveLevelRegion(params) {
        return instance.get(`/api/auth/v1/basicRegion/under/fiveLevelRegion`, { params })
    },
    childRegion(params) {
        return instance.get(`/api/auth/v1/basicRegion/childRegion`, { params })
    },
    detail(params) {
        return instance.get(`/api/auth/v1/basicRegion/detail/${params.id}`)
    },
    basicRegion(params) {
        return instance.post(`/api/auth/v1/basicRegion`, params )
    },
    update(params) {
        return instance.put(`/api/auth/v1/basicRegion`, params )
    },
    batchImport(params) {
        return instance.post(`/api/auth/v1/basicRegion/batchImport`, params )
    },
    selects(params) {
        return instance.get(`/api/theme/v1/volunt/servicetype/community/select/${params.communityId}`)
    },
    dutyDetail(params) {
        return instance.get(`/api/resident/v1/basic/dutyDetail/${params.id}`)
    },
    setduty(params) {
        return instance.put(`/api/resident/v1/basic/duty`,  params )
    },
}

/* 角色api */
export const RoleApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/role/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    addRole(params) {
        return instance.post(`/api/auth/v1/role`, params )
    },
    status(params) {
        return instance.put(`/api/auth/v1/role/status`, params )
    },
    detail(params) {
        return instance.get(`/api/auth/v1/role/detail/${params.id}` )
    },
    update(params) {
        return instance.put(`/api/auth/v1/role`, params )
    },
    updateMenu(params) {
        return instance.put(`/api/auth/v1/role/updateMenu`, params )
    },
    menuDetail(params) {
        return instance.get(`/api/auth/v1/role/menuDetail/${params.id}` )
    },
}

/* 用户api */
export const UserApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/user/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    roleSelect(params) {
        return instance.get(`/api/auth/v1/user/roleSelect`, { params } )
    },
    select(obj) {
        return instance.get(`/api/auth/v1/appUser/select/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    getUsername(params) {
        return instance.get(`/api/auth/v1/getUsername`, { params } )
    },
    detail(params) {
        return instance.get(`/api/auth/v1/user/detail/${params.id}`)
    },
    addUser(params) {
        return instance.post(`/api/auth/v1/user`, params )
    },
    updateUser(params) {
        return instance.put(`/api/auth/v1/user`, params )
    },
    status(params) {
        return instance.put(`/api/auth/v1/user/status`, params )
    },
    resetPassword(params) {
        return instance.put(`/api/auth/v1/user/${params.id}` )
    }
}

export const AppUserApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/appUser/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    code(params) {
        return instance.get(`/api/auth/v1/appUser/code/${params.id}` )
    },
    attestationList(params) {
        return instance.get(`/api/auth/v1/appUser/attestationList/${params.id}` )
    },
    //
    enable(params) {
        return instance.put(`/api/auth/v1/appUser/enable`, params)
    },
}


export const SealApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/seal/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post(`/api/auth/v1/seal`, params )
    },
    update(params) {
        return instance.put(`/api/auth/v1/seal`, params )
    },
    detail(params) {
        return instance.get(`/api/auth/v1/seal/detail/${params.id}` )
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/seal/${params.id}` )
    },
}

export const SysAppGeneralRulesApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/sysAppGeneralRules/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post(`/api/auth/v1/sysAppGeneralRules`, params )
    },
    update(params) {
        return instance.put(`/api/auth/v1/sysAppGeneralRules`, params )
    },
    detail(params) {
        return instance.get(`/api/auth/v1/sysAppGeneralRules/detail/${params.id}` )
    },
    status(params) {
        return instance.put(`/api/auth/v1/sysAppGeneralRules/status`, params )
    },
}

export const TicketsExchangeApi = {
    list(obj) {
        return instance.get(`/api/score/v1/tickets/exchange/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post(`/api/score/v1/tickets/exchange`, params )
    },
    update(params) {
        return instance.put(`/api/score/v1/tickets/exchange`, params )
    },
    detail(params) {
        return instance.get(`/api/score/v1/tickets/exchange/detail//${params.id}` )
    },
    delete(params) {

    }
}
export const SmsConfigApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/smsConfig/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    getStreetScene(params) {
        return instance.get(`/api/auth/v1/smsConfig/getStreetScene`, { params })
    },
    add(params) {
        return instance.post(`/api/auth/v1/smsConfig`, params )
    },
    update(params) {
        return instance.put(`/api/auth/v1/smsConfig`, params )
    },
    detail(params) {
        return instance.get(`/api/auth/v1/smsConfig/detail/${params.id}` )
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/smsConfig?id=${params.id}` )
    },
}

export const SmsConfigSendApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/smsConfig/sendList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    sendSmsList(obj) {
        return instance.get(`/api/auth/v1/smsConfig/scoreApplyUnaudited/sendSmsList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    familyRankListMonth(obj) {
        return instance.get(`/api/auth/v1/interconnectionCode/familyRankListMonth/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    applyNumList(obj) {
        return instance.get(`/api/auth/v1/interconnectionCode/applyNumList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    scoreBalance(obj) {
        return instance.get(`/api/auth/v1/interconnectionCode/scoreBalance/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    lastTimeList(obj) {
        return instance.get(`/api/auth/v1/interconnectionCode/lastTimeList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    familyRankList(obj) {
        return instance.get(`/api/auth/v1/interconnectionCode/familyRankList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    sendSms(params) {
        return instance.put(`/api/auth/v1/dx/sendSms`, params)
    }
}

export const CleanApi = {
    clean(params) {
        return instance.delete(`/api/resident/v1/basic/community/${params.id}` )
    }
}

/* 字典 */
export const DictApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/dict/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    listAllParent(params) {
        return instance.get(`/api/auth/v1/dict/listAllParent`, { params })
    },
    add(params) {
        return instance.post(`/api/auth/v1/dict`, params )
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/dict/${params.id}` )
    },
    getDictOne(params) {
        return instance.get(`/api/auth/v1/dict/getDictOne/${params.id}`)
    },
    update(params) {
        return instance.put(`/api/auth/v1/dict`, params )
    },
    dictCache(params) {
        return instance.get(`/api/auth/v1/dict/dictCache`, { params })
    },
    dtCache(params) {
        return instance.get(`/api/auth/v1/dict/dtCache`, { params })
    },
}

export const ThemeApi = {
    list(obj) {
        return instance.get(`/api/theme/v1/theme/picture/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    picture(params) {
        return instance.post('/api/theme/v1/theme/picture', params)
    },
    detail(params) {
        return instance.get('/api/theme/v1/theme/picture/detail/' + params.id)
    }
}

export const BasicApi = {
    list(obj) {
        return instance.get(`api/api/auth/v1/basic/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/auth/v1/basic/add', params)
    },
    getOne(params) {
        return instance.get('/api/auth/v1/basic/getOne', { params })
    },
    updateWords(params) {
        return instance.put('/api/auth/v1/basic/updateWords', params)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/basic/delete?id=${params.id}`)
    }
}

export const BasicConfigApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/basicConfig/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/auth/v1/basicConfig/add', params)
    },
    detail(params) {
        return instance.get('/api/auth/v1/basicConfig/getId', { params })
    },
    update(params) {
        return instance.put('/api/auth/v1/basicConfig/update', params)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/basicConfig/delete?id=${params.id}`)
    }
}

export const InitScoreApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/basic/initScore/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/auth/v1/basic/initScore', params)
    },
    update(params) {
        return instance.put('/api/auth/v1/basic/initScore', params)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/basic/initScore/delete/${params.id}`)
    },
    detail(params) {
        return instance.get(`/api/auth/v1/basic/initScore/detail/${params.id}`)
    },
    enabled(params) {
        return instance.put('/api/auth/v1/basic/initScore/enabled', params)
    },
}

//
export const GiveScoreTypeApi = {
    list(obj) {
        return instance.get(`/api/score/v1/account/detailed/giveScoreType/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/score/v1/account/detailed/giveScoreType',  params)
    },
    detail(params) {
        return instance.get('/api/score/v1/account/detailed/giveScoreType/detail', { params })
    },
    update(params) {
        return instance.put('/api/score/v1/account/detailed/giveScoreType', params)
    },
    enabled(params) {
        return instance.put('/api/score/v1/account/detailed/giveScoreType/enableOrDisable', params)
    },
    delete(params) {
        return instance.delete(`/api/score/v1/account/detailed/giveScoreType/${params.id}`)
    },
    copy(params) {
        return instance.post(`/api/score/v1/account/detailed/giveScoreType/copyGiveScoreType`, params)
    },
}

export const AppDefineApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/appDefine/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    appDefineSelect(params) {
        return instance.get('/api/auth/v1/appDefine/appDefineSelect', { params })
    },
    getId(params) {
        return instance.get('/api/auth/v1/appDefine/getId', { params })
    },
    add(params) {
        return instance.post('/api/auth/v1/appDefine/add',  params)
    },
    update(params) {
        return instance.put('/api/auth/v1/appDefine/update',  params)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/appDefine/delete?id=${params.id}`)
    }
}
export const SubscriptionTemplateApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/subscriptionTemplate/list/${obj.page.currentPage}/${obj.page.pageSize}`, {params: obj.params})
    },
    add(params) {
        return instance.post('/api/auth/v1/subscriptionTemplate',  params)
    },
    detail(params) {
        return instance.get(`/api/auth/v1/subscriptionTemplate/detail/${params.id}`)
    },
    update(params) {
        return instance.put('/api/auth/v1/subscriptionTemplate',  params)
    },
    //
    enabled(params) {
        return instance.put('/api/auth/v1/subscriptionTemplate/enable', params)
    },
    residentUsers(obj) {
        return instance.get(`/api/auth/v1/subscriptionTemplate/residentUsers/${obj.page.currentPage}/${obj.page.pageSize}`, {params: obj.params})
    },
    govUsers(obj) {
        return instance.get(`/api/auth/v1/subscriptionTemplate/govUsers/${obj.page.currentPage}/${obj.page.pageSize}`, {params: obj.params})
    },
    send(params) {
        return instance.put('/api/auth/v1/subscriptionTemplate/send', params)
    },
    communitySend(params) {
        return instance.put('/api/auth/v1/subscriptionTemplate/community/send', params)
    },
    govSend(params) {
        return instance.put('/api/auth/v1/subscriptionTemplate/gov/send', params)
    },
    sysSubscriptionLogList(obj) {
        return instance.get(`/api/auth/v1/sysSubscriptionLog/list/${obj.page.currentPage}/${obj.page.pageSize}`, {params: obj.params})
    },
}

export const SubscriptionMsgApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/subscriptionMsg/list/${obj.page.currentPage}/${obj.page.pageSize}`, {params: obj.params})
    },
    add(params) {
        return instance.post('/api/auth/v1/subscriptionMsg',  params)
    },
    update(params) {
        return instance.put('/api/auth/v1/subscriptionMsg',  params)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/subscriptionMsg?id=${params.id}`)
    },
    // 使用须知
    sysUseGuideList(obj) {
        return instance.get(`/api/auth/v1/sysUseGuide/list/${obj.page.currentPage}/${obj.page.pageSize}`, {params: obj.params})
    },
    sysUseGuideAdd(params) {
        return instance.post('/api/auth/v1/sysUseGuide',  params)
    },
    sysUseGuideUpdate(params) {
        return instance.put('/api/auth/v1/sysUseGuide',  params)
    },
    sysUseGuideDelete(params) {
        return instance.delete(`/api/auth/v1/sysUseGuide?id=${params.id}`)
    },
    sysUseGuideDetail(params) {
        return instance.get(`/api/auth/v1/sysUseGuide/detail/${params.id}`)
    }

}

//

export const SubscriptionTemplateDetailApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/subscriptionTemplateDetail/list/${obj.page.currentPage}/${obj.page.pageSize}`, {params: obj.params})
    },
    add(params) {
        return instance.post('/api/auth/v1/subscriptionTemplateDetail',  params)
    },
    update(params) {
        return instance.put('/api/auth/v1/subscriptionTemplateDetail',  params)
    },
    enable(params) {
        return instance.put('/api/auth/v1/subscriptionTemplateDetail/enable', params)
    },
}

export const StreetMenuApi = {
    detail(params) {
        return instance.get(`/api/auth/v1/streetMenu/detail/${params.id}`)
    },
    streetMenu(params) {
        return instance.put('/api/auth/v1/streetMenu',  params)
    }
}

export const PolicyApi = {
    typeList(obj) {
        return instance.get(`/api/auth/v1/basicPolicyType/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    basicPolicyType(params) {
        return instance.post('/api/auth/v1/basicPolicyType', params)
    },
    detail(params) {
        return instance.get(`/api/auth/v1/basicPolicyType/detail/${params.id}`)
    },
    update(params) {
        return instance.put('/api/auth/v1/basicPolicyType', params)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/basicPolicyType/${params.id}`)
    },
    contentList(obj) {
        return instance.get(`/api/auth/v1/basicPolicy/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    selectType() {
        return instance.get(`/api/auth/v1/basicPolicyType/select`)
    },
    addContent(params) {
        return instance.post(`/api/auth/v1/basicPolicy`, params)
    },
    updateContent(params) {
        return instance.put(`/api/auth/v1/basicPolicy`, params)
    },
    contentDetail(params) {
        return instance.get(`/api/auth/v1/basicPolicy/detail/${params.id}`)
    },
    contentDelete(params) {
        return instance.delete(`/api/auth/v1/basicPolicy/${params.id}`)
    },
    enableCont(params) {
        return instance.put(`/api/auth/v1/basicPolicy/enable`, params)
    }

}

export const NoticePlatformApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/noticePlatform/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post(`/api/auth/v1/noticePlatform`, params)
    },
    update(params) {
        return instance.put(`/api/auth/v1/noticePlatform`, params)
    },
    detail(params) {
        return instance.get(`/api/auth/v1/noticePlatform/detail/${params.id}`)
    },
    status(params) {
        return instance.put(`/api/auth/v1/noticePlatform/enable`, params)
    },
    community(params) {
        return instance.put(`/api/auth/v1/noticePlatform/community`, params)
    },
    communityList(params) {
        return instance.get(`/api/auth/v1/noticePlatform/communityList/${params.id}`)
    }
}

export const LargeScreenApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/screenDefine/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post(`/api/auth/v1/screenDefine`, params)
    },
    update(params) {
        return instance.put(`/api/auth/v1/screenDefine`, params)
    },
    detail(params) {
        return instance.get(`/api/auth/v1/screenDefine/detail/${params.id}`)
    },
    status(params) {
        return instance.put(`/api/auth/v1/screenDefine/status`, params)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/screenDefine`, { params })
    }
}

export const ScreenLogApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/screenLog/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    }
}

export const ScreenUserApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/screenUser/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post(`/api/auth/v1/screenUser`, params)
    },
    update(params) {
        return instance.put(`/api/auth/v1/screenUser`, params)
    },
    detail(params) {
        return instance.get(`/api/auth/v1/screenUser/detail/${params.id}`)
    },
    status(params) {
        return instance.put(`/api/auth/v1/screenUser/status`, params)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/screenUser`, { params })
    },
    resetPassword(params) {
        return instance.put(`/api/auth/v1/screenUser/reset/${params.id}`)
    },
    updatePwd(params) {
        return instance.put(`/api/auth/v1/screenUser/updatePwd`, params)
    },
}

export const ProblemFeedbackApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/problemFeedback/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    detail(params) {
        return instance.get(`/api/auth/v1/problemFeedback/detail/${params.id}`)
    },
    handle(params) {
        return instance.put(`/api/auth/v1/problemFeedback/handle`, params)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/problemFeedback`, { data: params })
    }
}

export const LogApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/errorLog/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    resetScore(obj) {
        return instance.get(`/api/score/v1/score/selfAccount/findList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/errorLog`, { data: params })
    },
    resetScoredelete(params) {
        return instance.delete(`/api/score/v1/score/selfAccount/${params.id}`)
    }
}


/**   政务管理  **/
export const ThreeServiceApi = {
    list(obj) {
        return instance.get(`/api/selfrule/v1/threeService/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/selfrule/v1/threeService', params)
    },
    detail(params) {
        return instance.get(`/api/selfrule/v1/threeService/detail/${params.id}`)
    },
    update(params) {
        return instance.put('/api/selfrule/v1/threeService', params)
    },
    delete(params) {
        return instance.delete(`/api/selfrule/v1/threeService/${params.id}`)
    },
    reviewList(obj) {
        return instance.get(`/api/selfrule/v1/threeService/review/list/${obj.page.currentPage}/${obj.page.pageSize}/${obj.params.serviceId}`,{ params: {bePrivate:obj.params.bePrivate} })
    },
    delReview(params) {
        return instance.get(`/api/selfrule/v1/threeService/review/${params.id}`)
    },
    auditList(obj) {
        return instance.get(`/api/selfrule/v1/threeService/audit/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    audit(params) {
        return instance.put('/api/selfrule/v1/threeService/audit', params)
    },
}

export const CategoryApi = {
    list(obj) {
        return instance.get(`/api/selfrule/v1/affairs/category/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/selfrule/v1/affairs/category', params)
    },
    detail(params) {
        return instance.get(`/api/selfrule/v1/affairs/category/detail/${params.id}`)
    },
    update(params) {
        return instance.put('/api/selfrule/v1/affairs/category', params)
    },
    delete(params) {
        return instance.delete(`/api/selfrule/v1/affairs/category/${params.id}`)
    },
    select(params) {
        return instance.get(`/api/selfrule/v1/affairs/category/select`, { params })
    }
}

export const AffairsContentApi = {
    list(obj) {
        return instance.get(`/api/selfrule/v1/affairs/content/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/selfrule/v1/affairs/content', params)
    },
    detail(params) {
        return instance.get(`/api/selfrule/v1/affairs/content/detail/${params.id}`)
    },
    update(params) {
        return instance.put('/api/selfrule/v1/affairs/content', params)
    },
    delete(params) {
        return instance.delete(`/api/selfrule/v1/affairs/content/${params.id}`)
    },
    reviewList(obj) {
        return instance.get(`/api/selfrule/v1/affairs/review/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    reviewDel(params) {
        return instance.delete(`/api/selfrule/v1/affairs/review/${params.id}`)
    }
}


export const AffairsAuditApi = {
    list(obj) {
        return instance.get(`/api/selfrule/v1/affairs/contentAudit/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    detail(params) {
        return instance.get(`/api/selfrule/v1/affairs/contentAudit/detail/${params.id}`)
    },
    contentAudit(params) {
        return instance.put('/api/selfrule/v1/affairs/contentAudit', params)
    }
}

export const OrgDefineApi = {
    tree(params) {
        return instance.get(`/api/auth/v1/org/define/tree`, { params })
    },
    list(obj) {
        return instance.get(`/api/auth/v1/org/define/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post(`/api/auth/v1/org/define`, params)
    },
    update(params) {
        return instance.put(`/api/auth/v1/org/define`, params)
    },
    detail(params) {
        return instance.get(`/api/auth/v1/org/define/detail/${params.id}`)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/org/define/${params.id}`)
    },
    orgDefineRegion(params) {
        return instance.get(`/api/auth/v1/org/define/orgDefineRegion/${params.id}`)
    }
}

export const OrgStaffApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/org/staff/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/org/staff/${params.id}`)
    }
}

export const CouncilApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/group/council/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    councilGiveScoreDetailList(obj) {
        return instance.get(`/api/auth/v1/sysStatistics/councilGiveScoreDetailList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    attestationOrgStaff(obj) {
        return instance.get(`/api/auth/v1/group/council/attestationOrgStaff/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    attestationResidentStaff(obj) {
        return instance.get(`/api/auth/v1/group/council/attestationResidentStaff/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post(`/api/auth/v1/group/council`, params)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/group/council/${params.id}`)
    },
    giveScoreByCouncil(params) {
        return instance.post(`/api/score/v2/staticCode/giveScoreByCouncil`, params)
    },
}

export const OrgStaffBasicApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/org/staffBasic/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    notInOrgBasic(obj) {
        return instance.get(`/api/auth/v1/org/staffBasic/notInOrgBasic/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    staff(params) {
        return instance.post(`/api/auth/v1/org/staff`, params)
    },
    add(params) {
        return instance.post(`/api/auth/v1/org/staffBasic`, params)
    },
    detail(params) {
        return instance.get(`/api/auth/v1/org/staffBasic/detail/${params.id}`)
    },
    update(params) {
        return instance.put(`/api/auth/v1/org/staffBasic`, params)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/org/staffBasic/${params.id}`)
    },
    permissionDetail(params) {
        return instance.get(`/api/auth/v1/org/staffBasic/permissionDetail/${params.id}`)
    },
    setPermission(params) {
        return instance.put(`/api/auth/v1/org/staffBasic/permission`, params)
    },
    staffGridSelect(params) {
        return instance.get(`/api/auth/v1/org/grid/staffGridSelect`, { params })
    },
    sync(params) {
        return instance.put(`/api/auth/v1/org/staffBasic/sync`, params)
    },
}

export const SupervisionApi = {
    deptList(obj) {
        return instance.get(`/api/auth/v1/supervision/dept/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    addDep(params) {
        return instance.post(`/api/auth/v1/supervision/dept`, params)
    },
    detail(params) {
        return instance.get(`/api/auth/v1/supervision/dept/detail/${params.id}`)
    },
    updateDep(params) {
        return instance.put(`/api/auth/v1/supervision/dept`, params)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/supervision/dept/${params.id}`)
    }
}

export const SettleAccountsProcessApi = {
    list(obj) {
        return instance.get(`/api/score/v1/settleAccountsProcess/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post(`/api/score/v1/settleAccountsProcess`, params)
    },
    update(params) {
        return instance.put(`/api/score/v1/settleAccountsProcess`, params)
    },
    delete(params) {
        return instance.delete(`/api/score/v1/settleAccountsProcess?id=${params.id}`)
    },
    supervisionUsers(obj) {
        return instance.get(`/api/score/v1/settleAccountsProcessPerson/supervisionUsers/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govUsers(obj) {
        return instance.get(`/api/score/v1/settleAccountsProcessPerson/govUsers/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    settleAccountsProcessPerson(params) {
        return instance.post(`/api/score/v1/settleAccountsProcessPerson`, params)
    },
    //
    settleAccountsProcessPersonList(obj) {
        return instance.get(`/api/score/v1/settleAccountsProcessPerson/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    detail(params) {
        return instance.get(`/api/score/v1/settleAccountsProcess/detail/${params.id}`)
    },
    delPersion(params) {
        return instance.delete(`/api/score/v1/settleAccountsProcessPerson?id=${params.id}`)
    },
    //
    save(params) {
        return instance.put(`/api/score/v1/settleAccountsProcessPerson/save`, params)
    },
    //
    enable(params) {
        return instance.put(`/api/score/v1/settleAccountsProcess/enable`, params)
    },
}

export const SupervisionUserApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/supervisionUser/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    addUser(params) {
        return instance.post(`/api/auth/v1/supervisionUser`, params)
    },
    updateUser(params) {
        return instance.put(`/api/auth/v1/supervisionUser`, params)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/supervisionUser/${params.id}`)
    },
    detail(params) {
        return instance.get(`/api/auth/v1/supervisionUser/detail/${params.id}`)
    },
    permission(params) {
        return instance.put(`/api/auth/v1/supervisionUser/permission`, params)
    },
    getRegions(params) {
        return instance.get(`/api/auth/v1/supervisionUserRegion/getRegions/${params.id}`)
    },
    supervisionUserRegion(params) {
        return instance.post(`/api/auth/v1/supervisionUserRegion`, params)
    }
}

export const OrgGridApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/org/grid/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    buildingTree(params) {
        return instance.get(`/api/auth/v1/basicRegion/buildingTree`, { params })
    },
    gridStaff(obj) {
        return instance.get(`/api/auth/v1/org/staffBasic/gridStaff/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/auth/v1/org/grid', params)
    },
    detail(params) {
        return instance.get(`/api/auth/v1/org/grid/detail/${params.id}`)
    },
    update(params) {
        return instance.put('/api/auth/v1/org/grid', params)
    },
    status(params) {
        return instance.put('/api/auth/v1/org/grid/status', params)
    },
}

export const GovPackageApi = {
    list(obj) {
        return instance.get(`/api/govern/v1/manage/findList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    familyList(obj) {
        return instance.get(`/api/govern/v1/manage/familyList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    getVillageList(obj) {
        return instance.get(`/api/govern/v1/manage/getVillageList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    getResidentList(obj) {
        return instance.get(`/api/govern/v1/manage/getResidentList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    attestationOrgStaff(obj) {
        return instance.get(`/api/govern/v1/manage/attestationOrgStaff/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post(`/api/govern/v1/manage`, params)
    },
    delete(params) {
        return instance.delete(`/api/govern/v1/manage/${params.id}`)
    },
    getFamilyDetail(obj) {
        return instance.get(`/api/govern/v1/manage/getFamilyDetail/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    getManagePeople(obj) {
        return instance.get(`/api/govern/v1/manage/getManagePeople/`, { params: obj.params })
    },
    manageApplyStatistics(obj) {
        return instance.get(`/api/govern/v1/manage/manageApplyStatistics/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    manageApplyExport(params) {
        return instance.get(`/api/govern/v1/manage/manageApplyStatisticsExport`, { params: params, responseType: 'blob'})
    },
    manageScoreStatistics(obj) {
        return instance.get(`/api/govern/v1/manage/manageScoreStatistics/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    managePointExport(params) {
        return instance.get(`/api/govern/v1/manage/manageScoreStatisticsExport`, { params: params, responseType: 'blob'})
    },
    billDel(params) {
        return instance.delete(`/api/govern/v1/manage/bill/${params.id}`)
    },
    addBill(params) {
        return instance.post(`/api/govern/v1/manage/bill`, params)
    },
    upload(params, fileData) {
        let str = '?'
        for (let key in params) {
            str = str + key + '=' + params[key]
        }
        return instance.put('/api/govern/v1/manage/excel/read' + str, fileData)
    },
    // 家庭被包
    getFamilyList(obj) {
        return instance.get(`/api/govern/v1/manage/familyCoverPack/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    // 家庭被包导出
    familyCoverPackExport(params) {
        return instance.get(`/api/govern/v1/manage/familyCoverPackExport`, { params: params, responseType: 'blob'})
    },
    //包联人员查询 
    managePeopleList(obj) {
        return instance.get(`/api/govern/v1/manage/managePeople/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    // 包联人员导出
    managePeopleExport(params) {
        return instance.get(`/api/govern/v1/manage/managePeopleExport`, { params: params, responseType: 'blob'})
    },
    // 包片区认证统计
    areaAuthStatisticstList(obj) {
        return instance.get(`/api/govern/v1/manage/packageAreaAuthStatistics/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    // 包片区认证导出
    packageFamilyAuthStatisticsExport(params) {
        return instance.get(`/api/govern/v1/manage/packageFamilyAuthStatisticsExport`, { params: params, responseType: 'blob'})
    },
    // 包家庭认证统计
    familyAuthStatisticsList(obj) {
        return instance.get(`/api/govern/v1/manage/packageFamilyAuthStatistics/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    // 包家庭认证导出
    packageFamilyAuthStatisticsExport(params) {
        return instance.get(`/api/govern/v1/manage/packageFamilyAuthStatisticsExport`, { params: params, responseType: 'blob'})
    },
    // 综合查询
    comprehensiveQuery(obj) {
        return instance.get(`/api/govern/v1/manage/comprehensiveQuery/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    // 综合查询导出
    comprehensiveQueryExport(params) {
        return instance.get(`/api/govern/v1/manage/comprehensiveQueryExport`, { params: params, responseType: 'blob'})
    },
}


export const OrgGroupApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/group/define/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    groupTree(params) {
        return instance.get('/api/auth/v1/group/define/tree', { params })
    },
    groupGridSelect(params) {
        return instance.get('/api/auth/v1/group/define/groupGridSelect', { params })
    },
    add(params) {
        return instance.post('/api/auth/v1/group/define', params)
    },
    update(params) {
        return instance.put('/api/auth/v1/group/define', params)
    },
    detail(params) {
        return instance.get(`/api/auth/v1/group/define/detail/${params.id}`)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/group/define/${params.id}`)
    }
}

export const GroupStaffApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/group/staff/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    attestationResidentStaff(obj) {
        return instance.get(`/api/auth/v1/group/staff/attestationResidentStaff/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    attestationOrgStaff(obj) {
        return instance.get(`/api/auth/v1/group/staff/attestationOrgStaff/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/auth/v1/group/staff', params)
    },
    update(params) {
        return instance.put('/api/auth/v1/group/staff', params)
    },
    detail(params) {
        return instance.get(`/api/auth/v1/group/staff/detail/${params.id}`)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/group/staff/${params.id}`)
    }
}


/*****   居民信息  ******/

export const ResidentPeopleInfoApi = {
    list(obj) {
        return instance.get(`/api/resident/v1/basic/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    basicSelect(obj) {
        return instance.get(`/api/resident/v1/basic/select/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/resident/v1/basic', params)
    },
    update(params) {
        return instance.put('/api/resident/v1/basic', params)
    },
    detail(params) {
        return instance.get(`/api/resident/v1/basic/detail/${params.id}`)
    },
    delete(params) {
        return instance.delete(`/api/resident/v1/basic/${params.id}`)
    },
    upload(params, fileData) {
        let str = '?'
        for (let key in params) {
            str = str + key + '=' + params[key]
        }
        return instance.put('/api/resident/v1/basic/excel/read' + str, fileData)
    },
    findBuildingName(params) {
        return instance.get(`/api/resident/v1/basic/findBuildingName/${params.id}`)
    },
    building(params) {
        return instance.put('/api/resident/v1/basic/building', params)
    },
    export(params) {
        return instance.get('/api/resident/v1/basic/export', { params, responseType: 'blob' })
    },
    userAttestation(params) {
        return instance.put('/api/resident/v1/peopleAttestation/userAttestation', params)
    },
    //
    up(params) {
        return instance.put('/api/resident/v1/score/selfAccount/up', params)
    },
    death(params) {
        return instance.put('/api/resident/v1/basic/death', params)
    }
}

export const PeopleAttestationApi = {
    list(obj) {
        return instance.get(`/api/resident/v1/peopleAttestation/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    detail(params) {
        return instance.get(`/api/resident/v1/peopleAttestation/detail/${params.id}`)
    },
    audit(params) {
        return instance.put(`/api/resident/v1/peopleAttestation/audit`, params)
    },
    export(params) {
        return instance.get(`/api/resident/v1/peopleAttestation/export`, { params, responseType: 'blob' })
    },
}

export const SysStatisticsApi = {
    list(obj) {
        return instance.get(`/api/auth/v1/sysStatistics/sysStatistics/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    export(params) {
        return instance.get(`/api/auth/v1/sysStatistics/exportSysStatistics`, { params, responseType: 'blob' })
    },
}

export const ResidentFamilyApi = {
    list(obj) {
        return instance.get(`/api/resident/v1/family/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    member(params) {
        return instance.get(`/api/resident/v1/family/member/${params.familyId}`)
    },
    //
    detail(params) {
        return instance.get(`/api/resident/v1/family/detail/${params.id}`)
    },
    updateFamilyInfo(params) {
        return instance.put(`/api/resident/v1/family`, params)
    },
    addAccount(params) {
        return instance.post(`/api/resident/v1/family/addAccount`, params)
    },
    unAttestationList(obj) {
        return instance.get(`/api/resident/v1/family/unAttestationList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    mergeHousehold(params) {
        return instance.put(`/api/resident/v1/family/mergeHousehold`, params)
    },
    downloadAllQr(params) {
        return instance.get(`/api/resident/v1/family/downloadAllQr`, { params: params, responseType: 'blob'  })
    },
    viewInterconnectionCode(params) {
        return instance.get(`/api/resident/v1/family/viewInterconnectionCode`, { params: params })
    },
}

export const EscrowApi = {
    list(obj) {
        return instance.get(`/api/resident/v1/basic/escrowUserList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    //
    person(params) {
        return instance.get(`/api/score/v1/score/order/staticQrcode/person`, { params: params })
    }
}

// 民主
export const democraticApi = {
    // 评议类别
    govReviewCategoryList(obj) {
        return instance.get(`/api/govern/v1/govReviewCategory/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewCategoryDetail(params) {
        return instance.get(`/api/govern/v1/govReviewCategory/detail/${params.id}`)
    },
    govReviewCategoryAdd(params) {
        return instance.post(`/api/govern/v1/govReviewCategory`, params)
    },
    govRevieweditState(params) {
        return instance.put(`/api/govern/v1/govReviewCategory/editState`, params)
    },
    govReviewCategoryUpdate(params) {
        return instance.put(`/api/govern/v1/govReviewCategory`, params)
    },
    govReviewCategoryDel(params) {
        return instance.delete(`/api/govern/v1/govReviewCategory/${params.id}`)
    },
    // 评议分项
    govReviewItemList(obj) {
        return instance.get(`/api/govern/v1/govReviewItem/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewItemDetail(params) {
        return instance.get(`/api/govern/v1/govReviewItem/detail/${params.id}`)
    },
    govReviewItemAdd(params) {
        return instance.post(`/api/govern/v1/govReviewItem`, params)
    },
    govReviewItemeditState(params) {
        return instance.put(`/api/govern/v1/govReviewItem/editState`, params)
    },
    govReviewItemUpdate(params) {
        return instance.put(`/api/govern/v1/govReviewItem`, params)
    },
    govReviewItemDel(params) {
        return instance.delete(`/api/govern/v1/govReviewItem/${params.id}`)
    },
    // 评议内容
    govReviewContentList(obj) {
        return instance.get(`/api/govern/v1/govReviewContent/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewContentDetail(params) {
        return instance.get(`/api/govern/v1/govReviewContent/detail/${params.id}`)
    },
    govReviewContentAdd(params) {
        return instance.post(`/api/govern/v1/govReviewContent`, params)
    },
    govReviewContenteditState(params) {
        return instance.put(`/api/govern/v1/govReviewContent/editState`, params)
    },
    govReviewContentUpdate(params) {
        return instance.put(`/api/govern/v1/govReviewContent`, params)
    },
    govReviewContentDel(params) {
        return instance.delete(`/api/govern/v1/govReviewContent/${params.id}`)
    },
    govReviewContentItemList(obj) {
        return instance.get(`/api/govern/v1/govReviewContentItem/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewContentItemAdd(params) {
        return instance.post(`/api/govern/v1/govReviewContentItem`, params)
    },
    govReviewContentItemDetail(params) {
        return instance.get(`/api/govern/v1/govReviewContentItem/detail/${params.id}`)
    },
    govReviewContentItemUpdate(params) {
        return instance.put(`/api/govern/v1/govReviewContentItem`, params)
    },
    govReviewContentItemDel(params) {
        return instance.delete(`/api/govern/v1/govReviewContentItem/${params.id}`)
    },
    govitemSelectList(obj) {
        return instance.get(`/api/govern/v1/govReviewItem/itemSelectList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govcategorySelectList(obj) {
        return instance.get(`/api/govern/v1/govReviewCategory/categorySelectList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewcopyContent(params) {
        return instance.post(`/api/govern/v1/govReviewContent/copyContent`, params)
    },
    // 民主团体
    govReviewDefineList(obj) {
        return instance.get(`/api/govern/v1/govReviewDefine/findList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewDefineDetail(params) {
        return instance.get(`/api/govern/v1/govReviewDefine/detail/${params.id}`)
    },
    govReviewDefineAdd(params) {
        return instance.post(`/api/govern/v1/govReviewDefine`, params)
    },
    govReviewDefineUpdate(params) {
        return instance.put(`/api/govern/v1/govReviewDefine`, params)
    },
    govReviewDefineDel(params) {
        return instance.delete(`/api/govern/v1/govReviewDefine/${params.id}`)
    },

    govReviewStaffList(obj) {
        return instance.get(`/api/govern/v1/govReviewStaff/findList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewStaffDetail(params) {
        return instance.get(`/api/govern/v1/govReviewStaff/detail/${params.id}`)
    },
    govReviewStaffAdd(params) {
        return instance.post(`/api/govern/v1/govReviewStaff`, params)
    },
    govReviewStaffUpdate(params) {
        return instance.put(`/api/govern/v1/govReviewStaff`, params)
    },
    govReviewStaffeDel(params) {
        return instance.delete(`/api/govern/v1/govReviewStaff/${params.id}`)
    },
    getselectResidentList(obj) {
        return instance.get(`/api/govern/v1/govReviewStaff/selectResidentList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    getselectStaffList(obj) {
        return instance.get(`/api/govern/v1/govReviewStaff/selectStaffList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    // 家庭互评团体
    govReviewFamilyGroupList(obj) {
        return instance.get(`/api/govern/v1/govReviewFamilyGroup/findList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewFamilyGroupDetail(params) {
        return instance.get(`/api/govern/v1/govReviewFamilyGroup/detail/${params.id}`)
    },
    govReviewFamilyGroupAdd(params) {
        return instance.post(`/api/govern/v1/govReviewFamilyGroup`, params)
    },
    govReviewFamilyGroupeditState(params) {
        return instance.put(`/api/govern/v1/govReviewFamilyGroup/editState`, params)
    },
    govReviewFamilyGroupUpdate(params) {
        return instance.put(`/api/govern/v1/govReviewFamilyGroup`, params)
    },
    govReviewFamilyGroupDel(params) {
        return instance.delete(`/api/govern/v1/govReviewFamilyGroup/${params.id}`)
    },
    // 家庭互评团体明细
    govReviewFamilyGroupItemList(obj) {
        return instance.get(`/api/govern/v1/govReviewFamilyGroupItem/findList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewFamilyGroupItemDetail(params) {
        return instance.get(`/api/govern/v1/govReviewFamilyGroupItem/detail/${params.id}`)
    },
    govReviewFamilyGroupItemAdd(params) {
        return instance.post(`/api/govern/v1/govReviewFamilyGroupItem`, params)
    },
    selectFamilyList(obj) {
        return instance.get(`/api/govern/v1/govReviewFamilyGroupItem/selectFamilyList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewFamilyGroupItemDel(params) {
        return instance.delete(`/api/govern/v1/govReviewFamilyGroupItem/${params.id}`)
    },
    // 家庭评议
    govReviewFamilyLists(obj) {
        return instance.get(`/api/govern/v1/govReviewFamily/findList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewFindFamilyList(obj) {
        return instance.get(`/api/govern/v1/govReviewFamily/findFamilyList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewFindFamilyByAwardList(obj) {
        return instance.get(`/api/govern/v1/govReviewFamilyAward/findFamilyListByAward/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewFamilyDetail(params) {
        return instance.get(`/api/govern/v1/govReviewFamily/detail/${params.id}`)
    },
    govReviewFamilyReviewerItemList(obj) {
        return instance.get(`/api/govern/v1/govReviewFamilyReviewerItem/findList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewFamilyReviewerList(obj) {
        return instance.get(`/api/govern/v1/govReviewFamilyReviewer/findList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewFamilydAdd(params) {
        return instance.post(`/api/govern/v1/govReviewFamily`, params)
    },
    
    govReviewFamilydread(params, fileData) {
        let str = '?'
        for (let key in params) {
            str = str + key + '=' + params[key]
        }
        return instance.put('/api/govern/v1/manage/excel/read' + str, fileData)
    },
    govReviewFamilyDel(params) {
        return instance.delete(`/api/govern/v1/govReviewFamily/${params.id}`)
    },
    govReviewFamilyUpdate(params) {
        return instance.put(`/api/govern/v1/govReviewFamily`, params)
    },
    // 家庭认定
    govReviewFamilyAwardList(obj) {
        return instance.get(`/api/govern/v1/govReviewFamilyAward/findList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewFamilyAwardDetail(params) {
        return instance.get(`/api/govern/v1/govReviewFamilyAward/detail/${params.id}`)
    },
    govReviewFamilyAwardAdd(params) {
        return instance.post(`/api/govern/v1/govReviewFamilyAward`, params)
    },
   
    govReviewFamilyAwardUpdate(params) {
        return instance.put(`/api/govern/v1/govReviewFamilyAward`, params)
    },
    govReviewFamilyAwardDel(params) {
        return instance.delete(`/api/govern/v1/govReviewFamilyAward/${params.id}`)
    },

    govReviewFamilyAwardItemList(obj) {
        return instance.get(`/api/govern/v1/govReviewFamilyAwardItem/findList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewFamilyAwardItemDel(params) {
        return instance.delete(`/api/govern/v1/govReviewFamilyAwardItem/${params.id}`)
    },
    govReviewFamilyAwardItemAdd(params) {
        return instance.post(`/api/govern/v1/govReviewFamilyAwardItem`, params)
    },

    govReviewFamilyAwardReviewList(obj) {
        return instance.get(`/api/govern/v1/govReviewFamilyAwardReview/findList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },

    // govReviewFamilyAwardReviewList(obj) {
    //     return instance.get(`/api/govern/v1/govReviewFamilyAwardReview/findList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    // },
    govReviewFamilyAwardReviewDetail(params) {
        return instance.get(`/api/govern/v1/govReviewFamilyAwardReview/detail/${params.id}`)
    },
    govReviewFamilyAwardReviewDel(params) {
        return instance.delete(`/api/govern/v1/govReviewFamilyAwardReview/${params.id}`)
    },
    govReviewFamilyAwardReviewTop(params) {
        return instance.put(`/api/govern/v1/govReviewFamilyAwardReview/toping`, params)
    },
    // 评议管理
    govReviewManageList(obj) {
        return instance.get(`/api/govern/v1/govReviewContent/reviewManageList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewFamilyList(obj) {
        return instance.get(`/api/govern/v1/govReviewContent/reviewFamily/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govMzpyPeopleList(obj) {
        return instance.get(`/api/govern/v1/govReviewContent/mzpyPeopleList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govJtpyPeopleList(obj) {
        return instance.get(`/api/govern/v1/govReviewContent/jtpyPeopleList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govfindFamilyReviewerCategoryList(obj) {
        return instance.get(`/api/govern/v1/govReviewContent/findFamilyReviewerCategory/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govfindFamilyReviewerItemList(obj) {
        return instance.get(`/api/govern/v1/govReviewContent/findFamilyReviewerItem/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    // 评议配置
    govReviewConfig(obj) {
        return instance.get(`/api/govern/v1/govReviewConfig/findList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    govReviewConfigeditState(params) {
        return instance.put(`/api/govern/v1/govReviewConfig/editState`, params)
    },
    govReviewConfigdAdd(params) {
        return instance.post(`/api/govern/v1/govReviewConfig`, params)
    },
   
    govReviewConfigUpdate(params) {
        return instance.put(`/api/govern/v1/govReviewConfig`, params)
    },
    govReviewConfigDel(params) {
        return instance.delete(`/api/govern/v1/govReviewConfig/${params.id}`)
    },
}
