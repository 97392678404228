 const appPropertyRouter = [
    {
        path: '/wygl/wyzc',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/appProperty/register/registerList')
    }  , {
         path: '/appProperty/register/insertRegister',
         component: () => import('@/views/appProperty/register/insertRegister')
     },
]

 export default appPropertyRouter